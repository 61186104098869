import styled from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  Fab,
  RadioGroup,
  Table,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AddIcon from "@mui/icons-material/Add";
import VerifiedIcon from "@mui/icons-material/Verified";
import DoneIcon from "@mui/icons-material/Done";
import QuizIcon from "@mui/icons-material/Quiz";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DownloadIcon from "@mui/icons-material/Download";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import SchoolIcon from "@mui/icons-material/School";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveIcon from "@mui/icons-material/Save";
import GroupIcon from "@mui/icons-material/Group";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LaunchIcon from "@mui/icons-material/Launch";
import NotesIcon from "@mui/icons-material/Notes";
import InfoIcon from "@mui/icons-material/Info";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DvrIcon from "@mui/icons-material/Dvr";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import AddBoxIcon from "@mui/icons-material/AddBox";

export const SBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: "calc(100vh - 64px)";
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const SCardForm = MuiStyled(Box)(({}) => ({
  position: "absolute",
  backgroundColor: "white",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "40%",
  overflow: "auto",
  borderRadius: "10px",
  padding: "1rem",
  zIndex: "200",
}));

export const SFormLarge = MuiStyled(Box)(({}) => ({
  position: "absolute",
  backgroundColor: "white",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  overflow: "auto",
  borderRadius: "10px",
  padding: "1rem",
  zIndex: "200",
}));

export const SSideBar = styled(Box)({
  backgroundColor: "lightgray",
  width: "12%",
  height: "calc(100vh - 64px)", // Adjusted height to take into account NavBar height
  position: "fixed",
  top: "64px", // Positioned below the NavBar
  left: 0,
  overflowY: "auto",
});

export const STable = MuiStyled(Table)(({}) => ({
  textAlign: "end",
  width: "100%",
  margin: "auto",
}));

export const SClose = MuiStyled(CloseIcon)(({ theme }) => ({
  color: "black",
  cursor: "pointer",
  transition: "color 0.3s ease, transform 0.3s ease",
  "&:hover": {
    color: "lightgrey",
    transform: "none",
  },
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8)",
    transformOrigin: "center",
  },
}));

export const SEdit = MuiStyled(EditIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SCreate = MuiStyled(AddIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SValidate = MuiStyled(VerifiedIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SDone = MuiStyled(DoneIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SResultOK = styled(QuizIcon)`
  color: green;
`;

export const SResultKO = styled(QuizIcon)`
  color: red;
`;

export const SQuizz = MuiStyled(AssignmentLateIcon)(({ theme }) => ({
  color: "blue",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SRefresh = styled(RefreshIcon)`
  color: white;
  &:hover {
    color: lightgrey;
  }
`;

export const SPhotoContainer = MuiStyled(Box)(({ theme }) => ({
  position: "relative",
  marginRight: "40px",
  width: "140px",
  [theme.breakpoints.down("sm")]: {
    width: "70px",
  },
}));

export const SPhoto = styled.img`
  width: 100%;
  border-radius: 50%;
`;

export const SAddPhoto = MuiStyled(AddAPhotoIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SNoPhoto = MuiStyled(NoPhotographyIcon)(({ theme }) => ({
  color: "white",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SDownloadPhoto = MuiStyled(DownloadIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SAddDocument = MuiStyled(PlaylistAddCircleIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SAddCriteria = MuiStyled(AddToPhotosIcon)(({ theme }) => ({
  color: "green",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SAddModule = MuiStyled(AddBoxIcon)(({ theme }) => ({
  color: "green",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SDownload = MuiStyled(DownloadIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SLogin = styled(Box)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 300px;
  height: 250px;
  margin-top: 100px;
  border-radius: 10px;
  display: block;
`;

export const SLogout = MuiStyled(LogoutIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SSearchBox = MuiStyled(Box)(({ theme }) => ({
  position: "fixed",
  right: "80px",
  bottom: "40px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  zIndex: "12",
  [theme.breakpoints.down("sm")]: {
    right: "40px",
  },
}));

export const SSearchDrawer = MuiStyled(Card)(({ theme }) => ({
  transition: "0.5s",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  height: "40px",
  borderRadius: "20px",
  width: "300px",
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
}));

export const SSearchInput = MuiStyled(TextField)(({ theme }) => ({
  m: 1,
  width: "300px",
  left: "25px",
  "& fieldset": { border: "none" },
  [theme.breakpoints.down("sm")]: {
    left: "5px",
    transform: "scale(0.8, 0.8)",
  },
}));

export const SSearchFab = MuiStyled(Fab)(({ theme }) => ({
  m: 1,
  left: "30px",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SAvatar = MuiStyled(Avatar)(({ theme }) => ({
  backgroundColor: "#89cff0",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.7, 0.7)",
  },
}));

export const SView = MuiStyled(VisibilityIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SCreateFolder = MuiStyled(CreateNewFolderIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const STypography = MuiStyled(Typography)(({ theme }) => ({
  fontSize: "15px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
}));

export const STypographyItem = MuiStyled(Typography)(({ theme }) => ({
  fontSize: "13px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "11px",
  },
}));

export const SRadioGroup = MuiStyled(RadioGroup)(({ theme }) => ({
  fontSize: "15px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
}));

export const STypographyTitle = MuiStyled(Typography)(({ theme }) => ({
  fontSize: "18px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
  },
}));

export const SLogo = MuiStyled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

export const SLogo2 = MuiStyled("div")({
  position: "absolute",
  top: "10%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

export const SUser = MuiStyled(AccountCircleIcon)(({ theme }) => ({
  color: "white",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SOpen = MuiStyled(AccountCircleIcon)(({ theme }) => ({
  color: "white",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SOpenProfile = MuiStyled(AccountCircleIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SAnalytics = MuiStyled(AnalyticsIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SMissing = MuiStyled(CloseIcon)(({ theme }) => ({
  color: "red",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SMissingFile = MuiStyled(QuestionMarkIcon)(({ theme }) => ({
  color: "red",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SReset = MuiStyled(CloseIcon)(({ theme }) => ({
  color: "grey",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SSchool = MuiStyled(SchoolIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SArrow = MuiStyled(SubdirectoryArrowRightIcon)(({ theme }) => ({
  color: "black",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SRocket = MuiStyled(RocketLaunchIcon)(({ theme }) => ({
  color: "orange",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SProduct = MuiStyled(ShoppingCartIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SInvoice = MuiStyled(AttachMoneyIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SSettings = MuiStyled(DisplaySettingsIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const STemplates = MuiStyled(DvrIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SCreditCard = MuiStyled(CreditCardIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SSendMail = MuiStyled(ForwardToInboxIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SStats = MuiStyled(QueryStatsIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SUsers = MuiStyled(GroupIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SDelete = MuiStyled(DeleteForeverIcon)(({ theme }) => ({
  color: "red",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SSave = MuiStyled(SaveIcon)(({ theme }) => ({
  color: "green",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SFile = MuiStyled(FilePresentIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SDate = MuiStyled(AccessTimeIcon)(({ theme }) => ({
  color: "green",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SInfo = MuiStyled(InfoIcon)(({ theme }) => ({
  color: "blue",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SUserAdd = MuiStyled(PersonAddIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SRedirect = MuiStyled(LaunchIcon)(({ theme }) => ({
  color: "white",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SNotes = MuiStyled(NotesIcon)(({ theme }) => ({
  color: "green",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));

export const SGenerate = MuiStyled(FileOpenIcon)(({ theme }) => ({
  color: "white",
  "&:hover": {
    color: "lightgrey",
  },
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.8, 0.8)",
  },
}));
