import { Divider } from "@mui/material";
import TopCustomers from "./display/TopCustomers";
import ExcursionCount from "./display/ExcursionCount";
import BoatCustomerCount from "./display/BoatCustomerCount";
import { STypographyTitle } from "../../../styles/StyledMUI";
import { Box } from "@mui/system";

export default function StatsComponent() {
  return (
    <Box sx={{ width: "100%" }}>
      <STypographyTitle padding={2}>Statistiques</STypographyTitle>
      <Divider />
      <ExcursionCount />
      <Divider />
      <BoatCustomerCount />
      <Divider />
      <TopCustomers />
      <Divider />
    </Box>
  );
}
