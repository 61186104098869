import React from "react";
import { Box, Divider, FormControlLabel, Switch, Typography } from "@mui/material";
import { STypography, STypographyTitle } from "../../../styles/StyledMUI";
import useZekay from "../../../contexts/controllers/useZekay";
import { SystemInfos, ZekayConfig } from "../../../api/zekay/Zekay.typed";
import { useAlert } from "../../../hooks/useAlert";
import StorageIcon from "@mui/icons-material/Storage";

export default function GeneralSettingsPage() {
  const { getConfig, updateConfig, getSystemInfos } = useZekay();
  const [config, setConfig] = React.useState<ZekayConfig>();
  const [infos, setInfos] = React.useState<SystemInfos>();
  const { showAlert } = useAlert();

  React.useEffect(() => {
    getConfig(1)
      .then((conf) => setConfig(conf))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  }, []);

  React.useEffect(() => {
    getSystemInfos()
      .then((infos) => setInfos(infos))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "error");
        }
      });
  }, []);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (config) {
      const updatedConfig = {
        ...config,
        AutomaticMailing: event.target.checked,
      };

      updateConfig(updatedConfig)
        .then(() => {
          setConfig(updatedConfig);
          showAlert("Configuration mise à jour avec succès", "success");
        })
        .catch((error) => {
          if (error instanceof Error) {
            showAlert(error.message, "error");
          }
        });
    }
  };

  if (!config) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", padding: 3 }}>
      <Box sx={{ marginBottom: 3 }}>
        <STypographyTitle>Paramètres généraux</STypographyTitle>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Mails */}
      <Box sx={{ marginBottom: 4 }}>
        <STypography variant="h6">Mails</STypography>
        <Box sx={{ paddingLeft: 4, marginTop: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={config.AutomaticMailing}
                onChange={handleToggleChange}
              />
            }
            label="Envoi automatique des emails de bienvenue"
          />
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Stockage */}
      <Box sx={{ marginBottom: 4 }}>
        <STypography variant="h6">Stockage</STypography>
        <Box sx={{ display: "flex", alignItems: "center", paddingLeft: 4, marginTop: 2 }}>
          <StorageIcon sx={{ marginRight: 2 }} />
          <Typography>
            {infos?.UsedSpace.toFixed(2)} Go utilisés sur {infos?.TotalSpace.toFixed(2)} Go disponibles
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}